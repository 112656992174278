/**
 * Status bar
 * Author: Graffino (http://www.graffino.com)
 */

.status {
  visibility: visible;
  opacity: 1;
  position: relative;
  border-top: 1px solid $color-pallete-10;
  text-align: center;
  transition: transition-custom(all);

  +below($medium) {
    @extend .h-z-index-20;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: none;
    transition: transition-custom(bottom);
  }

  &__bar {
    height: rem(76);
    background-color: white;

    &-wrapper {
      position: relative;
      display: none;
      height: 100%;

      +below($medium) {
        display: block;
      }
    }

    +below($medium) {
      position: relative;
      z-index: 20;
      height: rem(50);
      padding: rem(8) rem(12);
      background-color: $color-pallete-1;
    }
  }

  &.is-sticky {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 25px 50px 0 black;
    animation: 0.5s slide-in-top;

    +below($medium) {
      animation: none;
    }
  }

  &.is-invisible {
    visibility: hidden;
    opacity: 0;
  }

  &__dates {
    overflow: scroll;
    max-width: rem(285);
    white-space: nowrap;

    +below($medium) {
      max-width: 100%;
    }

    &-wrapper {
      +below($medium) {
        margin-top: rem(10);
        border-top: 1px solid $color-border-lighter;
        padding-top: rem(10);
      }
    }
  }

  &__opened {
    position: absolute;
    bottom: rem(-200);
    left: 0;
    z-index: 10;
    display: none;
    width: 100%;
    padding: rem(20);
    background-color: white;
    text-align: left;
    transition: transition-custom(bottom);

    +below($medium) {
      display: block;
    }

    &.is-open {
      bottom: 100%;
    }

    &-favorite {
      position: absolute;
      right: rem(20);
    }
  }

  &-list {
    position: relative;
    center(rem($width-container), pad = rem(20));
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: rem(22);
    padding-bottom: rem(22);
    padding-left: 0;
    list-style-type: none;
    text-align: left;

    +below($medium) {
      center(100%, pad = 0); // @stylint ignore
      padding-top: 0;
      padding-bottom: 0;
    }

    & ul {
      padding: 0;
    }

    &__right,
    &__left {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &__right {
      right: rem(20);

      +below($medium) {
        right: 0;
      }
    }

    &__left {
      left: rem(20);

      +below($medium) {
        left: 0;
      }
    }

    &__item {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding-right: rem(20);
      padding-left: rem(20);

      &:last-child {
        padding-right: 0;
      }

      &:first-child {
        padding-left: 0;

        &::before {
          content: none;
        }
      }

      &::before {
        content: "";
        position: absolute;
        {$center-vertically}; // @stylint ignore
        left: 0;
        display: block;
        width: 1px;
        height: rem(40);
        background-color: $color-border;

        +below($medium) {
          content: none;
        }
      }
    }
  }

  &__trigger {
    display: inline-block;
    padding: rem(8);
  }

  &__price {
    +below($medium) {
      .price-eur {
        color: white;
        font-size: $font-size-3;
      }
      .price-ron {
        color: $color-pallete-8;
        font-size: $font-size-7;
      }
    }
  }
}
