/**
 * Custom grid
 * Author: Graffino (http://www.graffino.com)
 */


// Variables
$width-wide = 1340px;
$width-tight = 740px;
$width-mobile = 490px;
$width-container = 1200px; // Maximum width of non-fullwidth content
$columns-gutter  = 1.65%; // Default column gutter width
$wide-gutter = 4%; // Default column gutter width


/**
 * Grid
 */

// Container
.grid {
  clearfix();
  center(rem($width-container), pad = rem(20));
  width: 100%;
  margin: 0 auto;

  &.-size-wide {
    center(rem($width-wide), pad = 0);
  }

  &.-size-mobile {
    center(rem($width-mobile), pad = 20);
  }

  &.-size-tight {
    center(rem($width-tight), pad = rem(20));
  }


  &.-background {
    &-white {
      background-color: $color-pallete-9;
    }
  }

  &.-no-padding-for {
    &-small-down {
      +below($small) {
        padding-right: 0;
        padding-left: 0;
      }
    }

    &-medium-down {
      +below($medium) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  &.is-debug {
    edit();
    * {
      transition: transition-custom($property = background);
    }
  }

  // Row
  &__row {
    clearfix();
    margin-right: -($columns-gutter);
    margin-left: -($columns-gutter);

    &.-wide-gutters {
      margin-right: -($wide-gutter);
      margin-left: -($wide-gutter);

      & ^[0]__column {
        padding-right: $wide-gutter;
        padding-left: $wide-gutter;
      }
    }

    &.-vertically-align {
      @supports (display: flex) {
        display: flex;
        align-items: center;

        +below($small) {
          display: block;
        }
      }

      &-for-medium-down {
        @supports (display: flex) {
          +below($medium) {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    &.-margin-bottom {
      &-1 {
        margin-bottom: rem(10);
      }

      &-2 {
        margin-bottom: rem(20);
      }

      &-3 {
        margin-bottom: rem(30);
      }

      &-4 {
        margin-bottom: rem(40);
      }
    }

    &.-padding-top {
      &-2 {
        padding-top: rem(20);
      }
      &-3 {
        padding-top: rem(30);
      }
    }

    &.-padding-bottom {
      &-1 {
        padding-bottom: rem(10);
      }

      &-2 {
        padding-bottom: rem(20);
      }

      &-3 {
        padding-bottom: rem(30);
      }

      &-4 {
        padding-bottom: rem(40);

        &-for-medium-down {
          +below($medium) {
            padding-bottom: rem(40);
          }
        }
      }
    }
  }

  // Column
  &__column {
    clearfix();
    padding-right: $columns-gutter;
    padding-left: $columns-gutter;
    transition: transition-custom();
    transition-property: left, right, width, margin, padding;

    &.-one {
      span(1/12);
    }
    &.-two {
      span(2/12);
    }
    &.-three {
      span(3/12);
    }
    &.-four {
      span(4/12);
    }
    &.-five {
      span(5/12);
    }
    &.-six {
      span(6/12);
    }
    &.-seven {
      span(7/12);
    }
    &.-eight {
      span(8/12);
    }
    &.-nine {
      span(9/12);
    }
    &.-ten {
      span(10/12);
    }
    &.-eleven {
      span(11/12);
    }
    &.-twelve {
      span(12/12);
    }

    &.-two-from {
      &-small-down {
        +below($small) {
          span(2/12);
        }
      }
    }

    &.-ten-from {
      &-small-down {
        +below($small) {
          span(10/12);
        }
      }
    }

    &.-twelve-from {
      &-large-down {
        +below($large) {
          span(12/12);
          unmove();
        }
      }

      &-medium-down {
        +below($medium) {
          span(12/12);
          unmove();
        }
      }

      &-tablet-down {
        +below($tablet) {
          span(12/12);
          unmove();
        }
      }

      &-small-down {
        +below($small) {
          span(12/12);
          unmove();
        }
      }
    }

    &.-five-from {
      &-medium-down {
        +below($medium) {
          span(5/12);
          unmove();
        }
      }
    }


    &.-six-from {
      &-medium-down {
        +below($medium) {
          span(6/12);
          unmove();
        }
      }
    }

    &.-six-from {
      &-large-down {
        +below($large) {
          span(6/12);
          unmove();
        }
      }

      &-tablet-down {
        +below($tablet) {
          span(6/12);
          unmove();
        }
      }

      &-small-down {
        +below($small) {
          span(6/12);
          unmove();
        }
      }
    }
  }
}
