/**
 * Filter
 * Author: Graffino (http://www.graffino.com)
 */


/**
 *  Filter
 */

.filters {
  position: relative;
  z-index: 30 !important;
  height: rem(76);
  background-color: white;
  text-align: center;
  transition: transition-custom(all);

  +below($medium) {
    position: fixed;
    top: 100vh;
    right: 0;
    left: 0;
    z-index: 40 !important;
    width: 100%;
    height: 100vh;
    padding: rem(40) rem(30);
  }

  &.is-open {
    top: 0;
  }

  &.is-sticky {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    animation: 0.5s slide-in-top;
  }

  &.is-invisible {
    visibility: hidden;
    opacity: 0;
  }

  .-type-figure {
    +below($large) {
      .form__icon {
        display: none;
      }
    }

    +below($medium) {
      width: calc-100-minus(30);

      .form__icon {
        position: absolute;
        top: 50%;
        right: 0;
        display: inline-block;
        transform: translateY(-50%);
      }
    }
  }

  &-list {
    position: relative;
    center(rem($width-container), pad = rem(20));
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: rem(22);
    padding-bottom: rem(22);
    padding-left: 0;
    text-align: left;
    list-style-type: none;
    @extend .h-z-index-10; // @stylint ignore

    +below($medium) {
      center(100%, pad = 0); // @stylint ignore
    }

    & ul {
      padding: 0;
    }

    &__right,
    &__left {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      +below($medium) {
        position: static;
        transform: none;
      }
    }

    &__right {
      right: rem(20);
    }

    &__left {
      left: rem(20);
    }

    &__item {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding-right: rem(30);
      padding-left: rem(30);

      & .select2 {
        +below($medium) {
          margin-top: rem(10);
        }
      }

      +below($medium) {
        display: block;
        border-bottom: 1px solid $color-pallete-7;
        padding: rem(20) 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &:first-child {
        padding-left: 0;

        &::before {
          content: none;
        }
      }

      &::before {
        content: "";
        position: absolute;
        {$center-vertically}; // @stylint ignore
        left: 0;
        display: block;
        width: 1px;
        height: rem(40);
        background-color: $color-border;

        +below($medium) {
          content: none;
        }
      }
    }
  }

  &__close {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    padding: rem(20) rem(30);

    +below($medium) {
      display: block;
    }
  }

  &__button {
    position: absolute;
    right: 0;
    bottom: rem(80);
    left: 0;
    padding-right: rem(30);
    padding-left: rem(30);

    & > a {
      @extend .h-z-index-50;
      position: relative;
    }
  }

  &-controls {
    @extend .h-z-index-10;
    position: fixed;
    right: 0;
    bottom: rem(30);
    left: 0;
    display: none;
    text-align: center;

    +below($medium) {
      display: block;
    }

    &__wrapper {
      display: inline-block;
      border-radius: rem(100);
      padding-right: rem(12);
      padding-left: rem(12);
      background-color: white;
      box-shadow: 0 rem(2) rem(10) 0 rgba(black, 0.1);
    }

    &__button {
      display: inline-block;
      padding: rem(10) rem(12);
      color: black;
      font-size: $font-size-5;
      font-weight: 600;
      text-decoration: none;
    }

    &__icon {
      margin-right: rem(5);
    }
  }
}
