/**
 * Default styles
 * Author: Graffino (http://www.graffino.com)
 */

// Variables
$focus-outline = @block {
  outline: none;
}


/**
 * Globals
 */

* {
  // Border-box
  box-sizing: border-box;

  // Custom focus state for DOM elements
  &:focus {
    {$focus-outline}; // @stylint ignore
  }
}

// Font rendering
html {
  position: relative;

  text-rendering: optimizeLegibility;
  font-variant-ligatures: common-ligatures;
  font-kerning: normal;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.is-fixed {
    +below($medium) {
      position: fixed;
      width: 100%;
      height: 100%;
    }
  }
}

// Body
body {
  overflow-x: hidden;
  min-width: rem(320);
  background-color: $color-background;
  color: $default-color;
  font-family: $default-fontface-stack;
  font-size: $default-font-size;
  line-height: $default-line-height;

  // Default font-family until webfonts are loaded
  .no-fonts & {
    font-family: $default-system-stack;
  }

  +below($medium) {
    // Cancel filters bottom spacing
    margin-bottom: 0 !important;
  }
}

html,
body {
  overflow-y: scroll;
  // Enable scroll momentum on iPhone Safari (webkit)
  -webkit-overflow-scrolling: touch;
}


/**
 * Default headings
 */

h1,
.h1 {
  font-family: $heading-1-font-family;
  font-size: $heading-1-font-size;
  font-weight: $heading-1-font-weight;
  line-height: $heading-1-line-height;
  letter-spacing: $heading-1-letter-spacing;

  // Default font-family until webfonts are loaded
  .no-fonts & {
    font-family: $heading-1-default-stack;
  }

  +below($small) {
    font-size: $font-size-2;
  }
}

h2,
.h2 {
  margin-top: rem(20);
  margin-bottom: rem(20);
  font-family: $heading-2-font-family;
  font-size: $heading-2-font-size;
  font-weight: $heading-2-font-weight;
  line-height: $heading-2-line-height;
  letter-spacing: $heading-2-letter-spacing;

  // Default font-family until webfonts are loaded
  .no-fonts & {
    font-family: $heading-2-default-stack;
  }

  +below($small) {
    margin-top: rem(14);
    margin-bottom: rem(14);
  }
}

h3,
.h3 {
  font-family: $heading-3-font-family;
  font-size: $heading-3-font-size;
  font-weight: $heading-3-font-weight;
  line-height: $heading-3-line-height;
  letter-spacing: $heading-3-letter-spacing;

  // Default font-family until webfonts are loaded
  .no-fonts & {
    font-family: $heading-3-default-stack;
  }
}

h4,
.h4 {
  font-family: $heading-4-font-family;
  font-size: $heading-4-font-size;
  font-weight: $heading-4-font-weight;
  line-height: $heading-4-line-height;
  letter-spacing: $heading-4-letter-spacing;

  // Default font-family until webfonts are loaded
  .no-fonts & {
    font-family: $heading-4-default-stack ;
  }
}

h5,
.h5 {
  font-family: $heading-5-font-family;
  font-size: $heading-5-font-size;
  font-weight: $heading-5-font-weight;
  line-height: $heading-5-line-height;
  letter-spacing: $heading-5-letter-spacing;

  // Default font-family until webfonts are loaded
  .no-fonts & {
    font-family: $heading-5-default-stack ;
  }
}

h6,
.h6 {
  margin-top: rem(15);
  margin-bottom: rem(15);
  font-family: $heading-6-font-family;
  font-size: $heading-6-font-size;
  font-weight: $heading-6-font-weight;
  line-height: $heading-6-line-height;
  letter-spacing: $heading-6-letter-spacing;

  // Default font-family until webfonts are loaded
  .no-fonts & {
    font-family: $heading-6-default-stack;
  }
}


/**
 * Default paragraph
 */

p {
  font-family: $default-fontface-stack;
  font-size: $default-font-size;

  +below($small) {
    margin-top: rem(10);
    margin-bottom: rem(10);
    font-size: $font-size-5;
  }
}


/**
 * Default link
 */

a {
  color: $default-link-color;

  &:hover {
    color: $default-link-hover;
  }
}

.link {
  color: $color-pallete-1;
  font-weight: 400;
  text-decoration: none;

  &:hover {
    color: darken($color-pallete-1, 20%);
  }

  &.-size-7 {
    font-size: $font-size-7;
    line-height: $line-height-7;
  }
}

&.content-divider {
  overflow: hidden;
  position: relative;
  width: 100%;
  text-align: center;

  &::after,
  &::before {
    content: "";
    position: absolute;
    bottom: rem(20);
    width: calc-50-minus(160);
    height: 0;
    margin-bottom: 0;
    border-top: 1px solid rgba($color-pallete-9, 10%);
  }

  &::after {
    right: 0;
  }

  &::before {
    left: 0;
  }
}

.divider {
  height: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-top: 0 solid $color-pallete-10;

  &.-transparency {
    border-color: rgba($color-pallete-9, 10%);
  }

  &[data-content] {
    position: relative;
    &::before {
      content: attr(data-content);
      position: absolute;
      top: 50%;
      left: 50%;
      padding-right: rem(20);
      padding-left: rem(20);
      background-color: white;
      font-size: rem(18);
      transform: translate(-50%, -50%);
    }
  }
}

[v-cloak] {
  display: none;
}

.address {
  font-style: normal;
}
