/**
 * Stylus mixins
 * Author: Graffino (http://www.graffino.com)
 */


/**
 * Base font sizes for EM / REM mixins
 */

$base-font-size    = 100%;
$base-font-size-px = 16px;

$center-vertically = @block {
  top: 50%;
  transform: translateY(-50%);
}

/**
 * Custom mixins
 */

// Convert to REM
rem($value) {
  return unit($value / $base-font-size-px, "rem"); // @stylint ignore
}

// Convert to EM
em($value) {
  return unit($value / $base-font-size-px, "em"); // @stylint ignore
}

// Convert Photoshop letter tracking value to rem for CSS letter-spacing rule
psLetterSpacingToRem($value) {
    return unit($value / 1000, "rem");
}

// Px to percent
px-to-percent($value, $max-value) {
  return $value * 100 / $max-value; // @stylint ignore
}

// Percent to px
percent-to-px($percent, $max-value) {
  return $percent * $max-value / 100; // @stylint ignore
}

// Convert value to string
string($value) {
  return "" + $value; // @stylint ignore
}

// CSS calc() subtract value from 100%
calc-100-minus($value) {
  return "calc(100% - %s)" % rem($value);
}

// CSS calc() add value to 100%
calc-100-plus($value) {
  return "calc(100% + %s)" % rem($value);
}

// CSS calc() substract value from 50%
calc-50-minus($value) {
  return "calc(50% - %s)" % rem($value);
}

// Output to pseudo-console
console($value) {
  body:before {
    content: "Stylus> " + string($value);
  }
}


/*
 * Font mixin
 */

webfont($family, $file, $weight = normal, $style = normal, $range = "U+0000-007F") {
  @font-face {
    font-family: $family;
    src: url("../fonts/" + $file + ".woff2") format("woff2"),
      url("../fonts/" + $file + ".woff") format("woff"), // @stylint ignore
      url("../fonts/" + $file + ".ttf") format("truetype"); // @stylint ignore
    font-style: $style;
    font-weight: $weight;
    unicode-range: $range; // @stylint ignore
  }
}


/**
 * Animation mixin
 */

transition-custom($property = all, $duration = 250ms, $ease = cubic-bezier(0.250, 0.460, 0.450, 0.940)) { // @stylint ignore
  return $property $duration $ease; // @stylint ignore
}
