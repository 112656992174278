/**
 * Select2
 * Author: Graffino (http://www.graffino.com)
 */

// Select2 - Default
$form-select-background-color = white;
$form-input-border-color-hover = $form-input-border-color;
$form-input-border-color-focus = $form-input-border-color;
$form-input-border-color-disabled = black;
$default-border-width = 1px;

.select2-parent {
    position: relative;

    &:hover {
        .select2 {
            &-container,
            &-dropdown {
              color: $form-input-border-color-hover !important;
            }

            &-container--disabled {
              color: $form-input-border-color-disabled!important;
            }
        }
    }
}

.select2 {
  // Disable outline on all children elements
  outline: 0;

  & * {
    outline: 0;
  }

  &-container {
    width: 100% !important;
    {$form-input-border}; // @stylint ignore
    border-color: currentColor;
    border-radius: rem(4) !important;
    background-color: $form-select-background-color;
    color: $form-input-border-color !important;

    &--below&--open {
      border-bottom-color: transparent !important;
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      box-shadow: 0 rem(5.2) rem(10) 0 rgba(0, 0, 0, 0.05);
    }

    &--above&--open {
      border-top-color: transparent !important;
      box-shadow: 0 rem(-4.4) rem(10) 0 rgba(0, 0, 0, 0.05);
    }

    &--disabled {
      color: $form-input-border-color-disabled !important;

      & ^[0]-selection__placeholder {
        color: $form-input-border-color-disabled !important;
      }
    }

    &--focus.&--disabled {
      box-shadow: none;
    }
  }

  &-container ~ &-container {
    @extend .h-z-index-10;
    border: 0 !important;
  }

  &-container--open&-container--below ~ &-container {
    box-shadow: 0 rem(5.2) rem(10) 0 rgba(0, 0, 0, 0.05);
  }

  &-container--open&-container--above ~ &-container {
    box-shadow: 0 rem(-4.4) rem(10) 0 rgba(0, 0, 0, 0.05);
  }

  &-selection {
    &__placeholder {
      color: $color-text !important;
      font-size: $default-font-size;
      transition: transition-custom(opacity);
    }

    &--default,
    &--single {
      height: auto !important;
      border: 0 !important;
      border-radius: rem(4) !important;
      background-color: $form-select-background-color;
    }

    &__rendered {
      margin: 0;
      border-radius: rem(4) !important;
      padding: 0 rem(20) !important;
      background-color: $form-select-background-color;
      color: black !important;
      font-size: $default-font-size !important;
      font-weight: 200;
      line-height: rem(55) !important;
    }

    &__arrow {
      top: 0 !important;
      right: 0 !important;
      display: block;
      width: rem(45) !important;
      height: 100% !important;
      margin: 0;
      padding: 0;

      & b {
        left: rem(14) !important;
        margin-top: 0 !important;
        margin-left: 0 !important;
        border-width: rem(5) rem(5) 0 rem(5) !important;
        border-style: solid !important;
        border-color: $color-pallete-12 transparent transparent transparent !important;
        transform: translateY(-50%);
      }
    }
  }

  &-container--open {
    b {
      border-width: 0 rem(5) rem(5) rem(5) !important;
      border-color: transparent transparent $color-pallete-12 transparent !important;
    }
  }

  &-dropdown {
    position: static;
    border: 1px solid $form-input-border-color !important;
    border-top: 0 !important;
    border-radius: 0;
    background-color: white;

    &--above {
      border-top: 1px solid $form-input-border-color !important;
      border-bottom: 0 !important;

      & ^[0]-results {
        padding: rem(30) 0 0;
      }
    }

    &--below {
      & ^[0]-results {
        padding: 0 0 rem(30);
      }
    }
  }

  &-results {
    &__options {
      padding-right: rem(15);
      padding-left: rem(15);
    }

    &__option {
      padding: rem(14) rem(15) rem(12);
      background-color: transparent !important;
      color: $color-text !important;
      font-size: $default-font-size;
      font-weight: 200;
      line-height: 1 !important;
      transition: transition-custom();
      transition-property: color;
      transition-duration: 0.15s;

      &[aria-disabled="true"] {
        opacity: 0.3;
      }

      &[aria-selected="true"] {
        color: black !important;
      }

      &--highlighted {
        border-radius: rem(100);
        background-color: $color-pallete-6 !important;
        color: black !important;

        +below($tablet) {
          border-radius: 0;
          background-color: transparent !important;
        }

        &[aria-selected="true"] {
          color: black !important;
        }
      }
    }
  }
}

.ui-state-error ~ .select2-container {
  border-color: $color-state-error !important;
}

// Select2 - Simple
.-type-simple {
  .select2-container + .select2-container {
    left: 0 !important;
    width: 100% !important;
  }

  .select2 {
    &-container {
      display: inline-block !important;
      width: initial !important;
      border: 0 !important;

      +below($medium) {
        float: right;
      }
    }

    &-dropdown {
      width: 100% !important;
    }

    &-results {
      width: 100% !important;
      padding: rem(10) !important;
    }

    &-selection {
        &__placeholder {
            color: black !important;

            +below($medium) {
              color: $color-text !important;
            }
        }

        &__rendered {
            padding: 0  rem(20) rem(0) rem(5) !important;
            line-height: 1 !important;

            +below($medium) {
              color: $color-text !important;
              font-family: $alt-sans-fontface-stack;
              font-size: $font-size-3;
              font-weight: 300;
            }
        }

        &__arrow {
            width: rem(20) !important;
        }
        &__arrow  b {
            top: 50% !important;
            left: 50% !important;
            border-width: rem(5) rem(5) 0 rem(5) !important;
            transform: translate(-50%, -50%);
        }
    }
    &-container--open b {
      border-width: 0 rem(5) rem(5) rem(5) !important;
    }
  }
}
