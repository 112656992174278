/**
 * Lists
 * Author: Graffino (http://www.graffino.com)
 */


/**
 * Unorderd lists
 */

.list {
  padding-left: rem(20);
  &.-color {
    &-black {
      color: black;
    }
  }

  &__item {
    vertical-align: middle;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  &.-type-nowrap {
    white-space: nowrap;
  }

  &.-type-simple {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
  }

  &.-type-inline {
    & ^[0]__item {
      display: inline-block;
    }
  }

  &.-type-indented {
    padding-left: rem(50);
  }

  &.-type-padded {
    & ^[0]__item {
      padding-right: rem(10);
      padding-left: rem(10);
    }
    &-3 {
      & ^[0]__item {
        padding-right: rem(30);
        padding-left: rem(30);
      }
    }
    &-half {
      & ^[0]__item {
        padding-right: rem(7);
        padding-left: rem(7);
      }
    }

    & ^[0]__item,
    &-3 ^[0]__item,
    &-half ^[0]__item {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  &.-stacks-for-small-down {
    & ^[0]__item {
      +below($small) {
        display: block;
        padding: rem(10) 0;

        & img {
          width: initial;
        }

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  &.-type-separators {
    & ^[0]__item {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 1px;
        height: rem(70);
        background-color: rgba(white, 20%);
        transform: translateY(-50%);
      }

      &:first-child {
        &::before {
          content: none;
        }
      }
    }
  }

&.-type-short-separators {
    & ^[0]__item {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: rem(2);
        height: 100%;
        background-color: $color-border;
        transform: translateY(-50%);
      }

      &:first-child {
        &::before {
          content: none;
        }
      }
    }
  }

  &.-type-vertically-padded {
    & ^[0]__item {
      padding-top: rem(10);
      padding-bottom: rem(10);
    }
  }
}
