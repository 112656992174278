/**
 * Forms
 * Author: Graffino (http://www.graffino.com)
 */


/**
 *  Standard form
 */
$form-input-border-color = $color-pallete-4;
$form-input-placeholder-color = $color-pallete-12;
$form-input-border = @block {
  border-width: 1px;
  border-style: solid;
  border-color: $form-input-border-color;
}
$form-input-padding = @block {
  padding: 0 rem(20);
}
$form-input-height = rem(55);
$form-input-text-size = $font-size-4;
$form-frame-width = 600;

.form {
  clearfix();
  position: relative;

  &.-type-framed {
    center(rem($form-frame-width), pad = rem(10));
  }

  &__date {
    &-wrapper {
      position: relative;
    }
  }

  &__fieldset {
    margin-bottom: rem(60);
    border: 0;
    padding: 0;

    +below($medium) {
      margin-bottom: rem(10);
    }
  }

  &__legend {
    margin-bottom: rem(30);
    color: black;
    font-family: $alt-sans-fontface-stack;
    font-size: rem(24);
    font-weight: 300;
    line-height: $line-height-4;

    +below($medium) {
      font-size: rem(18);
    }
  }

  &__label {
    display: inline-block;
    margin-top: rem(5);
    margin-bottom: rem(5);
    color: $color-text;
    font-size: $font-size-5;
    line-height: $line-height-6;

    &.-type-hidden {
      @extend .h-visually-hidden;
    }

    &.-type-spaced {
      margin-left: rem(10);
    }

    &.-type-hint,
    & .-type-hint {
      color: $color-valid;
      font-style: italic;
    }

    &.-type-inside {
      opacity: 0;
      position: absolute;
      top: rem(15);
      left: 0;
      padding-left: rem(20);
      font-size: rem(11);
      transition: transition-custom(opacity);
    }

    &.-type-inline {
        display: inline-block;
    }

    &.-type-radio,
    &.-type-checkbox {
      display: inline-block;
      padding: 0;
      color: black;
      font-weight: 600;

      +below($medium) {
        padding-left: rem(10);
        color: black;
        font-family: $alt-sans-fontface-stack;
        font-size: $font-size-3;
        font-weight: 300;
      }
    }
  }

  &__input,
  &__textarea,
  &__select {
    width: 100%;
    border-radius: rem(4);
    appearance: none;
    {$form-input-border}; // @stylint ignore
    {$form-input-padding}; // @stylint ignore
    height: $form-input-height;
    color: black;
    font-size: $form-input-text-size;
    line-height: $form-input-height;
    transition: transition-custom(padding);

    &.ui-state-error {
      border-color: $color-state-error;

      & ~ ^[0]__notice {
        display: block;
      }
    }

    &.is-not-empty {
      padding-top: rem(20);

      & ~ ^[0]__label {
        opacity: 1;
      }
    }

    &:hover {

    }

    &:focus,
    &:active {

    }

    &::placeholder {
      color: $form-input-placeholder-color;
      font-size: $font-input-text-size;
      line-height: $font-input-height;
    }
  }

  &__select.is-hidden-accessible {
    // extends select2's hidden-accessible
    overflow: hidden !important;
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    border: 0 !important;
    padding: 0 !important;
    clip: rect(0 0 0 0) !important;
  }

  &__action {
    visibility: visible;
    opacity: 1;
    display: inline-block;
    vertical-align: middle;
    transition: opacity 250ms cubic-bezier(0.250, 0.460, 0.450, 0.940), visibility 250ms cubic-bezier(0.250, 0.460, 0.450, 0.940);

    &.is-hidden {
      visibility: hidden;
      opacity: 0;
    }

    &.-position-right {
      +below($medium) {
        position: absolute;
        right: rem(10);
      }
    }
  }

  &__date {
    display: inline-block;
    vertical-align: middle;
    width: initial;
    min-width: rem(210);
    border: 0;
    padding: 0 0 0 rem(10);
    color: black;
    font-size: $font-size-5;
    font-weight: 600;
    line-height: 1.4;

    +below($xsmall) {
      font-size: rem(13);
      line-height: 1;
    }

    &::placeholder {
      color: $color-text;
      font-size: $font-size-5;
      font-weight: 400;

      +below($medium) {
        color: black;
        font-family: $alt-sans-fontface-stack;
        font-size: $font-size-3;
        font-weight: 300;
      }
    }
  }

  &__textarea {
    resize: none;
  }

  &__group {
    clearfix();
    margin-bottom: rem(50);
  }

  &__field {
    clearfix();
    position: relative;
    margin-bottom: rem(30);

    +below($medium) {
      margin-bottom: rem(30);
    }


    &-cards {
      position: absolute;
      top: rem(38);
      left: calc-100-plus(35);
      white-space: nowrap;

      +below($medium) {
        top: 0;
        right: 0;
        left: auto;
      }
    }
  }

  &__section {
    visibility: visible;
    opacity: 1;
    transition: opacity 250ms cubic-bezier(0.250, 0.460, 0.450, 0.940), visibility 250ms cubic-bezier(0.250, 0.460, 0.450, 0.940);

    &.-type-feedback {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }

    &.-type-full-screen{
      width: 100%;
      height: 100%;
    }

    &.is-hidden {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__notice {
    position: absolute;
    display: none;
    margin-top: rem(10);
    font-size: rem(16);
    line-height: $line-height-7;

    &.-type-error {
      position: relative;
      left: 0;
      width: 100%;
      color: $color-state-error;
      text-align: left;
    }

    &.-type-hint {
      display: block;
      font-style: italic;
    }

    &.-type-spaced {
      margin-left: rem(40);
      font-style: normal;
    }
  }

  // Checkbox
  &__checkbox-wrapper {
    clearfix();
    user-select: none;
    transition: transition-custom();
    transition-property: padding;

    &.-inline {
      float: left;
      padding-right: rem(16);
    }
  }

  &__checkbox {
    // display: none;
    @extend .h-visually-hidden;

    & ~ label {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      cursor: pointer;
      user-select: none;
    }

    & + label span {
      display: block;
      width: rem(22);
      height: rem(22);
      margin: rem(4) rem(2) rem(4) 0;
      border: solid 1px $color-border;
      border-radius: rem(2);
      background-position: center;
      background-repeat: no-repeat;
      transition: transition-custom();
      transition-property: width, height, margin, border-width, background-color, box-shadow;
      transition-duration: 0.15s;
    }

    &:focus + label span {
      {focus-outline}; // @stylint ignore
    }

    &:checked + label span {
      background-image: url("../icons/checkmark.svg");
    }
  }

  &__checkbox-date {
    // display: none;
    @extend .h-visually-hidden;

    & ~ label {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      cursor: pointer;
      user-select: none;

      .date {
        border: 1px solid $color-border;
        background-color: white;

        &__month {
          color: black;
        }
        &__checkmark {
          opacity: 0;
          transition: transition-custom(opacity);
        }
      }
    }

    &:focus + label span {
      {focus-outline}; // @stylint ignore
    }

    &:checked + label {
      .date {
        border: 0;
        background-color: rgba(black, 0.8);

        &__month {
          color: white;
        }
        &__checkmark {
          opacity: 1;
        }
      }
    }

    &.-type-sold .date,
    &:disabled .date {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../images/icons/sold-out.svg");
        background-position: center;
        background-size: cover;
      }
    }
  }

  // Radio buttons
  &__radio-wrapper {
    @extend .form__checkbox-wrapper;
  }

  &__radio {
    @extend .form__checkbox;

    & + label span {
      position: relative;
      width: rem(22);
      height: rem(22);
      margin: rem(12) rem(10) rem(12) 0;
      border-radius: 50%;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: none;
        width: rem(10);
        height: rem(10);
        margin: rem(-5) 0 0 rem(-5);
        border-radius: 50%;
        background-color: $color-pallete-1;
        transition: transition-custom();
        transition-property: width, height, margin;
        animation: fade-in 0.15s forwards;

        +below($large) {
          width: rem(6);
          height: rem(6);
          margin: rem(-3) 0 0 rem(-3);
        }
      }
    }

    &:checked + label span {
      background: none;

      &::before {
        display: block;
      }
    }
  }
}

.password-strength {
  position: absolute;
  top: 50%;
  right: rem(15);
  transform: translateY(-50%);

  &[data-strength=""] {
    visibility: hidden;
    opacity: 0;
    transition: oapcity 250ms ease-in-out, visibility 250ms ease-in-out;
  }

  &__text {
    color: $color-pallete-1;
    font-size: rem(14);
    text-transform: capitalize;
  }

  &__bars {
    & > span {
      display: inline-block;
      width: rem(16);
      height: rem(5);
      background-color: $color-border;

      &:first-child {
        border-top-left-radius: rem(100);
        border-bottom-left-radius: rem(100);
      }

      &:last-child {
        border-radius-top-right: rem(100);
        border-radius-bottom-right: rem(100);
      }
    }
  }

  &[data-strength="weak"] {
    opacity: 0.4;

    & ^[0]__bars {
      & > span {
        &:first-child {
          background-color: $color-pallete-1;
        }
      }
    }
  }
  &[data-strength="good"] {
    & ^[0]__bars {
      & > span {
        &:first-child,
        &:nth-child(2) {
          background-color: $color-pallete-1;
        }
      }
    }
  }

  &[data-strength="strong"] {
    & ^[0]__text {
      color: $color-valid;
    }
    & ^[0]__bars {
      & > span {
        background-color: $color-pallete-1;
      }
    }
  }
}
