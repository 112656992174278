/**
 * Icon sprite
 * Author: Graffino (http://www.graffino.com)
 * [This file has been automatically generated]
 * Use <symbol> directly in your HTML
 * Use https://github.com/jonathantneal/svg4everybody for remote svg sprites
 * HowTo: https://css-tricks.com/svg-symbol-good-choice-icons/,
 *        https://cloudfour.com/thinks/our-svg-icon-process/
 * Remote: https://github.com/Keyamoon/svgxuse
 */

sprite() {
  display: inline-block;
  vertical-align: middle;
  background-image: url("../icons/sprite.svg");
  background-size: rem(286) rem(271);
  background-repeat: no-repeat;
}


.i-arrow-btn-left {
  sprite();
  width: rem(40);
  height: rem(40);
  background-position: rem(-40) rem(-170);
}

.i-arrow-btn-right {
  sprite();
  width: rem(40);
  height: rem(40);
  background-position: rem(-124) rem(-128);
}

.i-arrow-down {
  sprite();
  width: rem(14);
  height: rem(22);
  background-position: rem(0) rem(-248.1);
}

.i-arrow-left-big {
  sprite();
  width: rem(15.3);
  height: rem(22);
  background-position: rem(-14) rem(-248.1);
}

.i-arrow-right {
  sprite();
  width: rem(22);
  height: rem(14);
  background-position: rem(-258) rem(-233);
}

.i-arrow-right-2 {
  sprite();
  width: rem(11);
  height: rem(14);
  background-position: rem(-164) rem(-152);
}

.i-button-minus {
  sprite();
  width: rem(40);
  height: rem(40);
  background-position: rem(0) rem(-170);
}

.i-button-play {
  sprite();
  width: rem(54);
  height: rem(54);
  background-position: rem(-54) rem(-74);
}

.i-button-plus {
  sprite();
  width: rem(40);
  height: rem(40);
  background-position: rem(-180) rem(0);
}

.i-button-reset {
  sprite();
  width: rem(40);
  height: rem(40);
  background-position: rem(-180) rem(-40);
}

.i-calendar {
  sprite();
  width: rem(28);
  height: rem(28);
  background-position: rem(-258) rem(-30);
}

.i-checkmark {
  sprite();
  width: rem(20);
  height: rem(17);
  background-position: rem(-134) rem(-110);
}

.i-checkmark-small {
  sprite();
  width: rem(11);
  height: rem(10);
  background-position: rem(-199) rem(-193);
}

.i-chevron {
  sprite();
  width: rem(14);
  height: rem(9);
  background-position: rem(-108) rem(-118);
}

.i-chevron-orange {
  sprite();
  width: rem(14);
  height: rem(9);
  background-position: rem(-194) rem(-160);
}

.i-chevron-reversed {
  sprite();
  width: rem(14);
  height: rem(9);
  background-position: rem(-180) rem(-160);
}

.i-close-big {
  sprite();
  width: rem(19);
  height: rem(19);
  background-position: rem(-229.5) rem(-210);
}

.i-close-small {
  sprite();
  width: rem(16);
  height: rem(17);
  background-position: rem(-115.3) rem(-248.1);
}

.i-cook {
  sprite();
  width: rem(39);
  height: rem(39);
  background-position: rem(-160) rem(-170);
}

.i-drinks {
  sprite();
  width: rem(38);
  height: rem(36);
  background-position: rem(-220) rem(-39);
}

.i-edit {
  sprite();
  width: rem(27);
  height: rem(27);
  background-position: rem(-258) rem(-186);
}

.i-email {
  sprite();
  width: rem(22);
  height: rem(19);
  background-position: rem(-207.5) rem(-210);
}

.i-events {
  sprite();
  width: rem(28);
  height: rem(30);
  background-position: rem(-258) rem(0);
}

.i-exit {
  sprite();
  width: rem(31);
  height: rem(31);
  background-position: rem(-220) rem(-179);
}

.i-facebook {
  sprite();
  width: rem(42);
  height: rem(42);
  background-position: rem(-42) rem(-128);
}

.i-facebook-simple {
  sprite();
  width: rem(14);
  height: rem(24);
  background-position: rem(-164) rem(-128);
}

.i-filters {
  sprite();
  width: rem(17);
  height: rem(18);
  background-position: rem(-98.3) rem(-248.1);
}

.i-hamburger {
  sprite();
  width: rem(18);
  height: rem(16);
  background-position: rem(-154) rem(-110);
}

.i-heart {
  sprite();
  width: rem(27.1);
  height: rem(24);
  background-position: rem(-258) rem(-162);
}

.i-heart-full {
  sprite();
  width: rem(29.4);
  height: rem(26);
  background-position: rem(-131.1) rem(-210);
}

.i-info {
  sprite();
  width: rem(38.1);
  height: rem(38.1);
  background-position: rem(0) rem(-210);
}

.i-instagram {
  sprite();
  width: rem(42);
  height: rem(42);
  background-position: rem(-134) rem(-68);
}

.i-iphone {
  sprite();
  width: rem(20);
  height: rem(28);
  background-position: rem(-258) rem(-58);
}

.i-location {
  sprite();
  width: rem(32);
  height: rem(33);
  background-position: rem(-220) rem(-146);
}

.i-location-simple {
  sprite();
  width: rem(16);
  height: rem(19);
  background-position: rem(-68.3) rem(-248.1);
}

.i-location-small {
  sprite();
  width: rem(30);
  height: rem(29.6);
  background-position: rem(-101.1) rem(-210);
}

.i-maps-pin {
  sprite();
  width: rem(28);
  height: rem(37);
  background-position: rem(-220) rem(-75);
}

.i-mastercard {
  sprite();
  width: rem(46);
  height: rem(34);
  background-position: rem(-134) rem(-34);
}

.i-mastercard-small {
  sprite();
  width: rem(26);
  height: rem(20);
  background-position: rem(-258) rem(-213);
}

.i-more {
  sprite();
  width: rem(8);
  height: rem(22);
  background-position: rem(-278) rem(-58);
}

.i-newsletter {
  sprite();
  width: rem(28);
  height: rem(28);
  background-position: rem(-258) rem(-134);
}

.i-next-arrow-gray {
  sprite();
  width: rem(40);
  height: rem(40);
  background-position: rem(-120) rem(-170);
}

.i-next-arrow-orange {
  sprite();
  width: rem(40);
  height: rem(40);
  background-position: rem(-80) rem(-170);
}

.i-paypal {
  sprite();
  width: rem(40);
  height: rem(40);
  background-position: rem(-180) rem(-120);
}

.i-paypal-small {
  sprite();
  width: rem(20);
  height: rem(20);
  background-position: rem(-29.3) rem(-248.1);
}

.i-pin {
  sprite();
  width: rem(19);
  height: rem(23);
  background-position: rem(-199) rem(-170);
}

.i-play {
  sprite();
  width: rem(14);
  height: rem(18);
  background-position: rem(-84.3) rem(-248.1);
}

.i-prev-arrow-gray {
  sprite();
  width: rem(40);
  height: rem(40);
  background-position: rem(-180) rem(-80);
}

.i-prev-arrow-orange {
  sprite();
  width: rem(40);
  height: rem(40);
  background-position: rem(-84) rem(-128);
}

.i-reset {
  sprite();
  width: rem(32);
  height: rem(32);
  background-position: rem(-69.1) rem(-210);
}

.i-reset-map {
  sprite();
  width: rem(19);
  height: rem(19);
  background-position: rem(-49.3) rem(-248.1);
}

.i-rural {
  sprite();
  width: rem(60);
  height: rem(35);
  background-position: rem(-74) rem(0);
}

.i-save-calendar {
  sprite();
  width: rem(31);
  height: rem(33);
  background-position: rem(-38.1) rem(-210);
}

.i-search {
  sprite();
  width: rem(34);
  height: rem(34);
  background-position: rem(-220) rem(-112);
}

.i-search-small {
  sprite();
  width: rem(24);
  height: rem(24);
  background-position: rem(-108) rem(-94);
}

.i-search-transparent {
  sprite();
  width: rem(24);
  height: rem(24);
  background-position: rem(-160.5) rem(-210);
}

.i-share {
  sprite();
  width: rem(26);
  height: rem(28);
  background-position: rem(-258) rem(-86);
}

.i-sold-out {
  sprite();
  width: rem(74);
  height: rem(74);
  background-position: rem(0) rem(0);
}

.i-sold-out-small {
  sprite();
  width: rem(54);
  height: rem(54);
  background-position: rem(0) rem(-74);
}

.i-sort {
  sprite();
  width: rem(28);
  height: rem(20);
  background-position: rem(-258) rem(-114);
}

.i-twitter {
  sprite();
  width: rem(42);
  height: rem(42);
  background-position: rem(0) rem(-128);
}

.i-twitter-simple {
  sprite();
  width: rem(23);
  height: rem(19);
  background-position: rem(-184.5) rem(-210);
}

.i-urban {
  sprite();
  width: rem(55);
  height: rem(34);
  background-position: rem(-74) rem(-35);
}

.i-vegetables {
  sprite();
  width: rem(38);
  height: rem(39);
  background-position: rem(-220) rem(0);
}

.i-visa {
  sprite();
  width: rem(46);
  height: rem(34);
  background-position: rem(-134) rem(0);
}

.i-visa-small {
  sprite();
  width: rem(26);
  height: rem(20);
  background-position: rem(-108) rem(-74);
}
