/**
 * Header
 * Author: Graffino (http://www.graffino.com)
 */


/**
 *  Main header
 */
$header-height = rem(80);
$header-height-mobile = rem(60);

.header {
  clearfix();
  @extend .h-z-index-40;
  position: fixed;
  top: 0;
  width: 100%;
  height: $header-height;
  transition: transition-custom(all);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
    height: rem(200);
    background-image: linear-gradient(-180deg, rgba(black, 0.30) 0, transparent 100%);
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(white, 0.1);
  }

  &.-type-splash {
    position: relative;
    height: rem(150);
    background-color: $color-background;
    background-image: url(../images/svgs/placeholder/background-trees.svg);
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;

    .logo {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      transform: translateY(-50%);
    }

    &::before,
    &::after {
      content: none;
    }
  }

  &__search {
    position: absolute;
    top: 0;
    left: rem(130);
    width: 100%;
    max-width: rem(420);

    +below($medium) {
      position: absolute;
      top: 0;
      left: rem(65);
      width: 100%;
      max-width: calc-100-minus(65);
    }

    &.is-open {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 100vh;
      background-color: white;
    }

    &.is-focused {
      background-color: white;
      // transition: transition-custom(all);
    }
  }

  &.is-hidden {
    top: rem(-80);
  }

  &.is-active,
  &.is-logged,
  &.-style-alternative {
    background-color: white;

    &::before {
      content: none;
    }

    &::after {
      background-color: $color-pallete-7;
    }

    & ^[0]__search {
      visibility: visible;
      opacity: 1;
      transition: transition-custom(all);

      .search__wrapper {
        border-right-color: $color-pallete-7;
        border-left-color: $color-pallete-7;

        +below($medium) {
          border-right: 0;
          border-left: 0;
        }
      }
    }

    .menu__link {
      color: $color-text;

      &:hover {
        color: $color-link-1-hover;
      }

      +below($medium) {
        color: black;
      }
    }
  }

  +below($medium) {
    height: $header-height-mobile;

    &.is-invisible {
      visibility: hidden;
      opacity: 0;
    }

    &.is-invisible.is-visible {
      visibility: visible;
      opacity: 1;
    }

    .search__wrapper {
      // minus menu dropdown width
      max-width: calc-100-minus(65);
    }

    .search-results {
      top: $header-height-mobile;
    }

    &::before {
      content: none;
    }
  }

  &__wrapper {
    clearfix();
    position: relative;
    @extend .h-z-index-20;

    +below($medium) {
      border-bottom: 1px solid $color-pallete-7;
      background-color: white;
    }
  }

  &__logo {
    position: relative;
    float: left;
    display: inline-block;
    margin-top: rem(6);
    margin-bottom: rem(6);
    padding-right: rem(40);

    +below($medium) {
      display: none;
    }

    .logo {
      &__anchor {
        display: inline-block;
      }

      &__image {
        display: none;
        vertical-align: top;

        &.-type-inactive {
          display: block;
        }

        ^[0].is-active,   // @stylint ignore
        ^[0].-style-alternative,   // @stylint ignore
        ^[0].is-logged { // @stylint ignore
          & ^[-1].-type-active {
            display: block;
          }

          & ^[-1].-type-inactive {
            display: none;
          }
        }
      }
    }
  }

  &__nav {
    position: relative;
    margin-top: rem(19);
    margin-bottom: rem(19);
    transition: transition-custom(all);

    +below($medium) {
      display: none;
    }
  }

  &__nav--mobile {
    @extend .h-z-index-10;
    position: absolute;
    top: -100vh;
    left: 0;
    display: none;
    width: 100%;
    height: 100vh;
    padding: rem(10) rem(30);
    background-color: white;
    transition: transition-custom(top);

    +below($medium) {
      display: block;
    }

    &.is-expanded {
      top: rem(60);
    }
  }

  &__nav--meta {
    position: absolute;
    bottom: rem(20);
  }
}

// Menu Dropdown
.menu-dropdown {
  position: relative;
  float: left;
  display: none;
  width: rem(65);
  height: rem(60);
  border-right: 1px solid $color-pallete-7;
  cursor: pointer;

  & > * {
    display: inline-block;
    vertical-align: middle;
  }

  &__icon {
    margin-top: rem(7);
    margin-left: rem(7);
  }

  +below($medium) {
    display: block;

    @supports (display: flex) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.is-active {
    .i-chevron {
      @extend .i-chevron-reversed;
    }
  }
}
