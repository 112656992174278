/**
 * Search
 * Author: Graffino (http://www.graffino.com)
 */


/**
 *  Hero search
 */

$search-input-height = rem(90);
$search-input-height-header = rem(80);
$search-input-height-header-mobile = rem(60);
$search-input-height-mobile = rem(70);
$search-font-size = rem(22);
$search-font-size-header = rem(18);
$search-font-size-mobile = $font-size-5;
$search-location-button-width = rem(170);
$search-location-button-width-header = rem(70);

.search {
  &__wrapper {
    clearfix();
    position: relative;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    &.-type-hero {
      max-width: rem(770);
      height: $search-input-height;
      border-radius: rem(4);
      background-color: white;
      box-shadow: 0 rem(2) rem(20) 0 rgba(black, 0.1);

      +below($small) {
        height: $search-input-height-mobile;
        border-radius: 0;
      }

      &::before {
        @extend .i-search;
        left: rem(30);

        +below($small) {
          @extend .i-search-small;
          left: rem(20);
        }
      }

      & ^[0]__button {
        width: $search-location-button-width;
        box-shadow: 0 rem(2) rem(20) 0 rgba(black, 0.1);

        &::before {
          @extend .i-location;
          left: rem(30);
        }

        +below($small) {
          width: rem(65);
          padding-left: 0;
          color: transparent;
          box-shadow: none;

          &::before {
            @extend .i-location-small;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      & ^[0]__input {
        // button width + input padding
        width: calc-100-minus(250);
        height: $search-input-height;
        padding: 0 0 0 rem(80);
        font-size: $search-font-size;
        line-height: $search-input-height;

        +below($small) {
          width: calc-100-minus(120);
          height: $search-input-height-mobile;
          padding-left: rem(50);
          font-size: $search-font-size-mobile;
          line-height: $search-input-height-mobile;
        }
      }
    }

    &.-type-header {
      float: left;
      max-width: rem(485);
      height: $search-input-height-header;
      border-right: 1px solid rgba(white, 0.1);
      border-left: 1px solid rgba(white, 0.1);

      &::before {
        @extend .i-search;
        left: rem(22);

        +below($medium) {
          @extend .i-search-small;
          left: rem(15);
        }
      }

      .search-results {
        top: $search-input-height-header;
      }

      +below($medium) {
        // minus menu-dropdown width
        max-width: calc-100-minus(65);
        height: $search-input-height-header-mobile;

        .search-results {
          top: $search-input-height-header-mobile;
        }
      }

      & ^[0]__button {
        width: $search-location-button-width-header;

        &::before {
          @extend .i-location-small;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        +below($medium) {
          display: none;
        }
      }

      & ^[0]__input {
        // button width + input padding
        width: calc-100-minus(125);
        height: $search-input-height-header;
        padding: 0 0 0 rem(55);
        font-size: $search-font-size-header;
        line-height: $search-input-height-header;

        +below($medium) {
          width: calc-100-minus(45);
          height: $search-input-height-header-mobile;
          padding-left: rem(45);
          line-height: $search-input-height-header-mobile;
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &.is-open {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      &.-type-hero {
        & ^[0]__button {
          box-shadow: none;
        }
      }

      &  ^[0]-results {
        display: block;
      }
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    background-color: transparent;
    color: black;
    text-overflow: ellipsis;

    &::placeholder {
      color: $color-text;
      font-family: $alt-sans-fontface-stack;
      font-weight: 100;
    }
  }

  &__button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-left: rem(70);
    font-size: $font-size-6;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;

    @supports (display: flex) {
      display: flex;
      align-items: center;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__close {
    display: none;
  }

  &.is-open {
    +below($medium) {
      @extend .h-z-index-40;
      position: absolute;
      // Menu trigger width: rem(65)
      left: rem(-65);
      width: calc-100-plus(65);
      height: 100vh;
      background-color: white;

      & ^[0]__wrapper {
        // Header menu height: rem(60)
        top: rem(60);
        max-width: 100%;
        border-top: 1px solid $color-pallete-7;
        border-bottom: 1px solid $color-pallete-7;
      }

      & ^[0]__button {
        top: rem(-60);
        display: block;
      }

      & ^[0]__close {
        position: absolute;
        top: rem(-60);
        display: block;
        padding: rem(20);
      }
    }

    & ^[0]__button {
      box-shadow: none;
    }
  }
}
.search-results-container {
  @extend .h-z-index-10;
  position: absolute;
  top: rem(80);

  +below($medium) {
    top: $search-input-height-mobile;
    width: 100%;
  }

  &.-type-header {
    +above($medium) {
      position: fixed;
      left: 0;
      width: 100%;
      background-color: white;
      box-shadow: inset 0 rem(2) rem(20) 0 rgba(black, 0.1); // @stylint ignore

      .search-results {
        width: 100%;
        max-width: $width-container;
        margin-right: auto;
        margin-left: auto;
        padding-left: rem(170);
        background-color: transparent;
        box-shadow: none;
      }
    }

    +below($medium) {
      top: rem(58);
    }
  }

  &.is-visible{
    display: block;
  }
  &.is-hidden {
    display: none;
  }
}
.search-results::-webkit-scrollbar {
  display: none;
}
.search-results {
  overflow: scroll;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom-right-radius: rem(4);
  border-bottom-left-radius: rem(4);
  padding: rem(20) rem(50);
  background-color: white;
  text-align: left;
  box-shadow: inset 0 rem(2) rem(20) 0 rgba(black, 0.1); // @stylint ignore
  list-style-type: none; // @stylint off

  +below($medium) {
    padding: rem(10) rem(20);
  }

  +below($small) {
    box-shadow: none;
  }

  &__highlight {
    color: black;
  }

  &__link {
    text-decoration: none;
  }

  &__item {
    padding: rem(10) rem(30) rem(8);
    font-size: rem(22);

    &:hover {
      border-radius: rem(100);
      background-color: $color-pallete-6 !important;
    }
  }
}
