/**
 * Pagination
 * Author: Graffino (http://www.graffino.com)
 */


/**
 *  Pagination
 */

.pagination {
  list-style-type: none;
  padding-left: 0;
  text-align: center;

  & > li {
    display: inline-block;
    color: $color-text;

    &.active,
    &:hover {
      color: $color-pallete-1;
    }

    & > a {
      display: inline-block;
      width: rem(36);
      height: rem(36);
      color: inherit;
      line-height: rem(36);
      text-decoration: none;
    }

    // Prev arrow
    &:first-child {
      @extend .i-prev-arrow-orange;

      &.disabled {
        @extend .i-prev-arrow-gray;
      }
    }

    // Next arrow
    &:last-child {
      @extend .i-next-arrow-orange;

      &.disabled {
        @extend .i-next-arrow-gray;
      }
    }
  }
}
