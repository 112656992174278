/**
 * Menus
 * Author: Graffino (http://www.graffino.com)
 */


/**
 *  Main navigation
 */

.menu {
  @extend .h-z-index-10;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;

  &__item {
    position: relative;
    display: inline-block;
    padding-right: rem(15);
    padding-left: rem(15);

    &:last-child {
      padding-right: 0;
    }

    +below($medium) {
      display: block;
      padding-right: 0;
      padding-left: 0;
    }

    +below($medium) {
      border-bottom: 1px solid $color-pallete-7;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &__extra {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: rem(30);
    margin-right: rem(10);
    text-align: center;

    +below($medium) {
      position: absolute;
      top: 50%;
      right: 0;
      margin-right: 0;
      transform: translateY(-50%);
    }
  }

  &__link {
    display: inline-block;
    padding-top: rem(15);
    padding-bottom: rem(15);
    color: white;
    font-size: rem(13);
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;

    +below($medium) {
      color: black;
      font-family: $alt-sans-fontface-stack;
      font-size: $font-size-3;
      font-weight: 300;
      text-transform: none;
    }
  }
}

// Acount
.account-avatar {
  overflow: hidden;
  width: rem(30);
  height: rem(30);
  border-radius: 100%;
  background-image: url("../images/placeholders/placeholder-avatar.jpg");
  background-position: center;
  background-size: cover;

  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }
}


// Favorites
.favorites {
  &.has-favorites {
    .i-heart {
      @extend .i-heart-full;
    }
  }

  &__count {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    font-size: rem(13);
    font-weight: 600;
    line-height: 2;
    transform: translate(-50%, -50%);
  }
}
