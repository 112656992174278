/**
 * Account
 * Author: Graffino (http://www.graffino.com)
 */

.account {
  margin-top: rem(100px);

  +below($medium) {
    margin-top: rem(70);
  }

  &__navigation,
  &__tabs {
    background-color: white;
  }

  &__navigation {
    margin-top: 0;
    text-align: center;

    +below($medium) {
      border-bottom: 0;
      text-align: left;
    }
  }

  &__heading {
    +below($medium) {
      border-bottom: 0;
    }
  }

  &-menu {
    list-style-type: none;
    margin-top:0;
    margin-bottom: 0;
    padding: rem(10);
    text-align: left;

    +below($medium) {
      padding: rem(5);

      @supports (display: flex) {
        display: flex;
        justify-content: center;
      }
    }

    +below($small) {
      @supports (display: flex) {
        display: flex;
        justify-content: space-between;
      }
    }

    &__item {
      width: 100%;
      color: black;
      font-size: $font-size-5;
      font-weight: 600;

      +below($medium) {
        float: left;
        display: inline-block;
        width: initial;
        font-size: rem(12);
      }
    }

    &__link {
      display: block;
      padding: rem(10) rem(15);
      color: inherit;
      text-decoration: none;

      & span[class^="i-"] {
        margin-right: rem(10);

        +below($medium) {
          display: block;
          margin-right: auto;
          margin-bottom: rem(8);
          margin-left: auto;
        }
      }

      &:hover {
        border-radius: rem(100);
        background-color: $color-background;
        color: inherit;

        +below($medium) {
          border-radius: rem(8);
        }
      }
    }
  }

  &-content {
    background-color: white;

    &.-type-centered {
      @extend .h-vertically-align-with-flex;
      text-align: center;

      +below($medium) {
        min-height: rem(300);
      }
    }

    &.-rounded-decoration {
      background-image: url("../images/svgs/rounded-decoration.svg");
      background-position: center bottom;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__heading {
    +below($medium) {
      border-bottom: 0;
    }
  }

  &__avatar {
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: rem(120);
    height: rem(120);
    margin-top: rem(30);
    margin-right: auto;
    margin-left: auto;
    border-radius: rem(100);
    background-image: url("../images/placeholders/placeholder-avatar.jpg");
    background-position: center;
    background-size: cover;

    & > img {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      display: block;
      width: 100%;
      height: auto;
      transform: translateY(-50%);
    }

    +below($medium) {
      float: left;
      max-width: rem(70);
      height: rem(70);
      margin-top: 0;
      margin-right: rem(20);
    }
  }
}
