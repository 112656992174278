/**
 * Buttons
 * Author: Graffino (http://www.graffino.com)
 */


 /**
  *  Standard button
  */
$button-font-size = rem(14);
$button-font-size-fat = rem(16);
$button-font-size-thin = rem(13);
$button-line-height = 1;
$button-gradient-1 = #FFB05E;
$button-gradient-2 = #F89126;
$button-padding = @block {
  padding: rem(20) rem(40) rem(16);
}
$button-padding-mobile = @block {
  padding: rem(17) rem(40) rem(15);
}
$button-padding-fat = @block {
  padding: rem(28) rem(54) rem(26);
}
$button-padding-thin = @block {
  padding: rem(12) rem(20) rem(10);
}

$color-facebook-1 = #5092C4;
$color-facebook-2 = #4568B2;
$color-twitter-1 = #4CC3F0;
$color-twitter-2 = #12ABE0;

.button {
  position: relative;
  display: inline-block;
  border: 0;
  border-radius: rem(100);
  {$button-padding} // @stylint ignore
  background-image: linear-gradient(90deg, $button-gradient-1 0, $button-gradient-2 100%);
  color: white;
  font-size: $button-font-size;
  font-weight: 600;
  line-height: $button-line-height;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  text-align: center;

  &:hover {
    &::before {
      opacity: 0;
    }
  }

  +below($medium) {
    {$button-padding-mobile} // @stylint ignore
  }


  &::before {
    content: "";
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: rem(100);
    background-image: linear-gradient(-90deg, $button-gradient-1 0, $button-gradient-2 100%);
    transition: transition-custom(opacity, 500ms);
  }

  & > span {
    position: relative;
    z-index: 1;
  }

  &.is-disabled {
    pointer-events: none;
    background-image: none;

    &::before {
      content: none;
    }
  }

  &.-size {
    &-fat {
      {$button-padding-fat} // @stylint ignore
      font-size: $button-font-size-fat;
    }

    &-thin {
      {$button-padding-thin}; // @stylint ignore
      font-size: $button-font-size-thin;
    }
    &-full {
      width: 100%;
    }

    &-paypal {
      padding-top: rem(7);
      padding-bottom: rem(4);
    }

    &-full-for-small-down {
      +below($small) {
        width: 100%;
      }
    }
  }

  &.-no-border-radius {
    &-for-small-down {
      +below($small) {
        border-radius: 0;
      }
    }
  }

  &.-text {
    &-black {
      color: black;

      &:hover {
        color: $color-pallete-1;
      }
    }
  }

  &.-color {
    &-white {
      background-color: white;
      background-image: none;
      color: $color-pallete-1;

      &::before {
        content: none;
      }
    }

    &-gray {
      border: 1px solid $color-border;
      background-image: none;
      color: $color-text;

      &:hover {
        border-color: 1px solid $color-pallete-1;
        background-image: none;
        color: $color-pallete-1;
      }

      &::before {
        content: none;
      }
      &.-type-disabled,
      &:disabled {
        opacity: 0.4;

        &:hover {
          color: $color-text;
        }
      }
    }

    &-light-orange {
      background-color: $color-pallete-11;
      background-image: none;

      &::before {
        content: none;
      }

      &:hover {
        background-color: lighten($color-pallete-11, 15%);
      }
    }
  }

  &.-type-case-normal {
    text-transform: none;
  }

  &.-type-border {
    border: 1px solid $color-border;
    background-color: white;
    background-image: none;
    color: black;

    &:hover {
      color: $color-pallete-1;
    }

    &::before {
      content: none;
    }
  }

  &.-type-video {
    padding-top: rem(18);
    padding-left: rem(50);
    background-color: rgba(black, 0.4);
    background-image: none;

    &:hover {
      &::before {
        opacity: 1;
      }
    }

    &::before {
      opacity: 0;
      background-image: linear-gradient(-90deg, transparent 0, black 100%);
    }

    &::after {
      content: "";
      @extend .i-play;
      position: absolute;
      top: 50%;
      left: rem(15);
      transform: translateY(-50%);
    }

    &.-color-opaque {
      background-color: $color-pallete-10;
      color: black;

      &::before {
        content: none;
      }

      &:hover {
        background-color: darken($color-pallete-10, 20%);
      }
    }
  }
}

a.button {
  color: white;
  text-decoration: none;
}

// Link button
.button--dropdown {
  @extend .button;
  margin-right: rem(5);
  margin-left: rem(5);
  padding: rem(11) rem(30) rem(9) rem(20);
  text-decoration: none;

  &:hover {
    & > span {
      color: white;
    }
  }

  &::after {
    content: "";
    opacity: 0.5;
    position: absolute;
    top: 50%;
    right: rem(14);
    display: block;
    width: 0;
    height: 0;
    border-width: 5px 5px 0 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    transform: translateY(-50%);
  }
}

// Link button
.button--link {
  display: inline-block;
  color: white;
  font-size: rem(13);
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    color: $color-pallete-1;
  }

  &.-color-orange {
    color: $color-pallete-1;

    &:hover {
      color: darken($color-pallete-1, 20%);
    }
  }
}

// Action button
.button--action {
  @extend .button;
  width: 100%;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: white;
  }

  & > span {
    display: inline;
  }

  & [class^="i-"] {
    margin-right: rem(10);
  }

  &.-color-facebook {
    background-image: linear-gradient(90deg, $color-facebook-1 0, $color-facebook-2 100%);

    &::before {
      background-image: linear-gradient(-90deg, $color-facebook-1 0, $color-facebook-2 100%);
    }

    &.-type-ghost {
      border: 1px solid currentColor;
      background-image: none;
      color: $color-facebook-1;

      &:hover {
        color: $color-facebook-2;
      }

      &::before {
        content: none;
      }
    }
  }

  &.-color-twitter {
    background-image: linear-gradient(90deg, $color-twitter-1 0, $color-twitter-2 100%);

    &::before {
      background-image: linear-gradient(-90deg, $color-twitter-1 0, $color-twitter-2 100%);
    }

    &.-type-ghost {
      border: 1px solid currentColor;
      background-image: none;
      color: $color-twitter-1;

      &:hover {
        color: $color-twitter-2;
      }

      &::before {
        content: none;
      }
    }
  }
}

.button--icon {
  opacity: 0.4;
  display: inline-block;

  &:hover {
    opacity: 1;
  }
}

.buttons-set {
  .button {
    margin-right: rem(10);
    margin-left: rem(10);

    +below($small) {
      margin-right: 0;
      margin-bottom: rem(20);
      margin-left: 0;

      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &.-equal-widths {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    .button {
      flex: 1 0 0;

      +below($small) {
        margin-right: rem(10);
        margin-left: rem(10);

        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
