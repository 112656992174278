/**
 * Main stylesheet (stylus) file
 * Author: Graffino (http://www.graffino.com)
 */


/**
 * Dependencies
 */

// Jeet
@import "../../node_modules/jeet/styl/index";
// Normalizer
@import "../../node_modules/normalize.styl/_normalize";
// Rupture
@import "../../node_modules/rupture/rupture/index";


/**
 * Common
 */

// Stylus CSS Console
@import "common/console";
// Mixins
@import "common/mixins";
// Breakpoints
@import "common/breakpoints";
// Colors
@import "common/colors";
// Fonts & sizes
@import "common/fonts";
// Lists
@import "common/lists";
// Animations
@import "common/animations";
// Custom grid
@import "common/grid";
// Default styles
@import "common/defaults";
// Helpers
@import "common/helpers";


/**
 * Vendor
 */

// Generated SVG icon sprite
@import "vendor/sprite";


/**
 * Modules
 */

// Typography — body copy, headings, lists, blockquotes, etc
@import "modules/typography";
// Miscellaneous — styles and modules
@import "modules/miscellaneous";
// Header — styles and modules
@import "modules/header";
// Hero — styles and modules
@import "modules/hero";
// Search — styles and modules
@import "modules/search";
// Page sections — project specific areas
@import "modules/sections";
// Menus — desktop & mobile
@import "modules/menus";
// Events
@import "modules/events";
// Manifesto
@import "modules/manifesto";
// Form elements - styles
@import "modules/forms";
// Button — styles and sizes
@import "modules/buttons";
// Page - inner page
@import "modules/pages";
// Footer — styles
@import "modules/footer";
// Partners — styles
@import "modules/partners";
// Booking — styles
@import "modules/booking";
// Fitlers
@import "modules/filters";
// Status
@import "modules/status";
// Food — styles
@import "modules/food";
// Feautred — section
@import "modules/featured";
// Account — Widget
@import "modules/account";
// Custom Controls (dropdown, tooplitp, etc)
@import "modules/custom-controls";
// Pagination
@import "modules/pagination";

/**
 * Libraries
 */

// Select2 — styles
@import "lib/select2";
// Datepicker — styles
@import "lib/datepicker";
// Popup — styles
@import "lib/popup";
// Googl - Map
@import "lib/map";

/**
 * Pages
 */

// Home — styles
@import "pages/home";
