/**
 * Events
 * Author: Graffino (http://www.graffino.com)
 */


/**
 *  Event card
 */

$event-padding = @block {
  padding: rem(30) rem(30) rem(10);
}
$event-padding-small = @block {
  padding: rem(20);
}
$event-margin = @block {
  margin-top: rem(60);
  margin-bottom: rem(35);
}

$event-margin-small = @block {
  margin-top: rem(35);
  margin-bottom: rem(20);
}

.event {
  {$event-margin}; // @stylint off
  {$event-padding}; // @stylint off
  min-height: rem(385);
  background-color: white;
  transition: box-shadow 250ms ease-in-out;

  +below($medium) {
    min-height: initial;
  }

  &:hover {
    box-shadow: 0 rem(2) rem(10) 0 rgba(0, 0, 0, 0.05);
  }

  +below($medium) {
    {$event-margin-small}; // @styling off
    {$event-padding-small}; // @styling off
  }

  &__hero {
    position: relative;
    overflow: hidden;
    margin-top: rem(-80);
    height: rem(250);
    background-image: url("../images/placeholders/placeholder.jpg");
    background-size: cover;
    background-position: center;

    +below($medium) {
      margin-top: rem(-55);
      height: rem(140);
    }

    & img {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      min-width: 100%;
      min-height: 100%;
      transform: translate(-50%, -50%);
    }
  }

  &__content {
    float: left;
    width: 80%;
  }

  &__link {
    display: block;
    color: inherit;
    text-decoration: none;
  }

  &__name {
    width: 80%;

    +below($small) {
      width: 100%;
    }
  }

  &__date {
    position: absolute;
    top: rem(20);
    left: rem(20);

    +below($medium) {
      top: rem(10);
      left: rem(10);
    }
  }

  &__body {
    clearfix();
    position: relative;
    padding-top: rem(15);

    +below($medium) {
      padding-top: rem(5);
    }
  }

  &__price {
    position: absolute;
    top: rem(30);
    right: rem(5);
    text-align: right;

    +below($medium) {
      position: static;
      text-align: left;
    }
  }

  &__badges {
    overflow: scroll;
    position: absolute;
    width: 100%;
    max-width: 80%;
    top: rem(-38);
    left: rem(8);
    white-space: nowrap;

    +below($medium) {
      top: rem(-25);
      left: rem(2);
    }
  }

  &__favorite {
    position: absolute;
    top:rem(-25);
    right: rem(5);
    display: inline-block;
    width: rem(50);
    height: rem(50);
    border-radius: 100%;
    background-color: white;
    box-shadow: 0 rem(2) rem(8) rem(-4) rgba(black, 0.1);

    @supports (display: flex) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.event-list {
}

.event-item {
  position: relative;
  padding: rem(40) rem(30);

  +below($small) {
    padding: rem(20) rem(15);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 90%;
    height: 1px;
    background-color: $color-border;

    +below($small) {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:first-child {
    &::before {
      content: none;

      +below($medium) {
        content: "";
      }
    }
  }

  &__date {
    height: rem(60);

    +below($small) {
      max-width: rem(30);
      height: rem(45);
    }
  }

  &__title {
    padding-left: rem(35);

    +below($medium) {
      padding-left: 0;
    }

    +below($small) {
      margin-bottom: rem(15);
      padding-left: rem(20);
    }
  }

  &__buttons {
    @supports(display: flex) {
      display: flex;
      flex-flow: row wrap;
    }

    & > * {
      margin: rem(8) rem(8);
    }
  }
}
