/**
 * Featured
 * Author: Graffino (http://www.graffino.com)
 */
$featured-color-green = #06A94B;
$featured-color-green-light = #22D16C;
$featured-color-green-mid = #06C001; // Arrrow
$featured-gradient = linear-gradient(-90deg, $featured-color-green-light 19%, $featured-color-green 80%);

.featured {
  position: relative;
  padding-top: rem(24);
  padding-bottom: rem(24);
  background-color: $featured-color-green;
  background-image: $featured-gradient;
  text-align: center;

  +below($medium) {
    background-image: none;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: rem(-5);
    left: 50%;
    width: 0;
    height: 0;
    border-width: rem(5) rem(10) 0 rem(10);
    border-style: solid;
    border-color: $featured-color-green-mid transparent transparent transparent;
    transform: translateX(-50%);

    +below($medium) {
      border-top-color: $featured-color-green;
    }
  }
}
