/**
 * Food
 * Author: Graffino (http://www.graffino.com)
 */


/**
 *  Inner page
 */

.food {
  margin-top: rem(20);
  padding-bottom: rem(20);
  background-image: url("../images/svgs/placeholder/background.svg");
  background-position: center bottom;
  background-repeat: no-repeat;

  +below($small) {
    padding-right: rem(20);
    padding-bottom: rem(75);
    padding-left: rem(20);

    & .grid {
      padding: 0;
    }
  }

  &__content {
    clearfix();
    padding-top: rem(40);
    padding-bottom: rem(48);
    background-color: $color-pallete-9;

    +below($small) {
      margin-bottom: rem(60);
    }
  }

  &__block {
    border-right: 1px solid $color-pallete-10;
    padding-right: rem(30);

    +below($medium) {
      padding-right: rem(20);
    }

    +below($small) {
      border: 0;
      padding-right: rem(14);
      padding-left: rem(14);
      text-align: center;
    }
  }

  &__title {
    color: $color-heading;
    font-family: $alt-sans-fontface;
    font-size: rem(30);
    font-weight: 300;
  }

  &__logo {
    margin-top: 0;
    padding-left: rem(75);

    +below($small) {
      margin-bottom: rem(54);
      padding-left: 0;
    }
  }

  &__item {
    display: inline-block;
  }
}

.posts {
  &__title {
    display: inline-block;
    margin-bottom: 0;
    padding-left: rem(30);
    color: $color-heading;
    font-family: $sans-fontface;
    font-size: rem(16);
    font-weight: 600;

    +below($small) {
      margin-bottom: rem(30);
      padding-left: rem(10);
    }
  }

  &__text {
    overflow: hidden;
    margin-top: rem(10);
    padding-left: rem(72);
    font-family: $sans-fontface;
    font-size: rem(16);
    font-weight: 400;

    +below($tablet) {
      padding-left: 0;
    }

    +below($small) {
      margin-bottom: rem(54);
    }
  }
}

.chefs {
  &__content {
    +below($large) {
      padding-left: rem(40);
    }

    +below($medium) {
      padding-top: rem(20);
      padding-left: rem(5);
    }

    +below($small) {
      padding-bottom: rem(15);
    }
  }

  &__post {
    display: block;
    margin-bottom: rem(26);

    +below($small) {
      margin-bottom: rem(18);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__image {
    display: inline-block;
    vertical-align: top;
    border-radius: rem(40);
  }

  &__block {
    display: inline-block;
    padding-left: rem(25);

    +below($medium) {
      padding-left: rem(5);
    }
  }

  &__title {
    margin-bottom: rem(55);
    color: $color-heading;
    font-family: $alt-sans-fontface;
    font-size: rem(30);
    font-weight: 300;

    +below($small) {
      margin-bottom: rem(79);
    }
  }

  &__name {
    margin-bottom: rem(4);
    color: $color-heading;
    font-family: $sans-fontface;
    font-size: rem(18);
    font-weight: 600;
  }

  &__text {
    display: inline-block;
    margin-top: rem(4);
    font-family: $sans-fontface;
    font-size: rem(16);
    font-weight: 400;

    +below($small) {
      min-width: rem(140);
    }
  }
}
