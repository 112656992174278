/**
 * Pages : Home
 * Author: Graffino (http://www.graffino.com)
 */

.home {
/**
 *  Header
 */
  .header {
    &.is-inactive.&.is-not-logged {
      &::after {
        content: none;
      }
    }

    &.is-inactive:not(.is-logged) {
      & .header__search {
        visibility: hidden;
        opacity: 0;
        transition: transition-custom(all);

        +below($medium) {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .hero.not-logged {
    +below($small) {
      height: rem(540);
    }
  }
}
