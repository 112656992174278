/**
 * Custom Controls
 * Author: Graffino (http://www.graffino.com)
 */

// Tooltip
.tooltip-container {
  position: relative;
  display: inline-block;

  &:hover {
    .tooltip {
      visibility:  visible;
      opacity: 1;
    }
  }
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: calc-100-plus(8);
  left: 50%;
  border-radius: rem(10);
  padding: rem(2) rem(8);
  background-color: rgba(black, 0.8);
  color: white;
  font-size: $font-size-7;
  white-space: nowrap;
  transform: translateX(-50%);

  &::after {
    content: "";
    position: absolute;
    bottom: rem(-3);
    left: 50%;
    width: 0;
    height: 0;
    border-width: rem(3) rem(3) 0 rem(3);
    border-style: solid;
    border-color: rgba(black, 0.8) transparent transparent transparent;
    transform: translateX(-50%);
    transition: all 250ms ease-in-out;
  }
}

// Hover Dropdown
.dropdown-container {
  position: relative;
  display: inline-block;

  &:hover {
    .dropdown {
      visibility:  visible;
      opacity: 1;
    }
  }
}

.dropdown {
  list-style-type: none;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 300;
  margin-top: 0;
  margin-bottom: 0;
  border: 1px solid $color-pallete-10;
  border-radius: rem(4);
  padding: rem(10) rem(20);
  background-color: white;
  white-space: nowrap;
  box-shadow: 0 1px rem(10) 0 rgba(black, 0.10);

  &__item {
    color: $color-pallete-5;

    &:hover {
      color: $color-pallete-1;
    }
  }

  &__link {
    display: inline-block;
    padding-top: rem(10);
    padding-bottom: rem(10);
    color: inherit;
    font-size: $font-size-5;
    line-height: 1;
    text-decoration: none;
  }
}
