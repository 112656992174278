/**
 * Miscellaneous
 * Author: Graffino (http://www.graffino.com)
 */

// Date
$date-small-style = @block {
  min-width: rem(32);
  min-height: rem(36);

  & ^[0]__day {
    font-size: $font-size-5;
  }

  & ^[0]__month {
    font-size: $font-size-8;
  }
}

.date {
  position: relative;
  display: inline-block;
  min-width: rem(45);
  min-height: rem(50);
  border-radius: rem(4);
  padding: rem(2);
  background-color: rgba(black, 0.8);
  text-align: center;

  @supports (display: flex) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &:not(.-size-large) {
    +below($medium) {
      {$date-small-style}; // @stylint ignore
    }
  }

  &.-size-small {
    {$date-small-style}; // @stylint ignore
  }

  &.-color-white {
    border: 1px solid $color-border;
    background-color: white;

    & ^[0]__month {
      color: black;
    }
  }

  &__day {
    color: $color-pallete-1;
    font-size: $font-size-3;
    line-height: $line-height-8;
  }

  &__month {
    color: white;
    font-size: $font-size-6;
    line-height: $line-height-8;
    text-transform: uppercase;
  }

  &__checkmark {
    position: absolute;
    bottom: rem(2);
  }
}

// Price
.price {
  color: $color-pallete-1;

  &-eur {
    display: block;
    font-family: $alt-sans-fontface-stack;
    font-size: rem(26);
    font-weight: 500;
    letter-spacing: -0.4px;

    +below($medium) {
      display: inline;
      font-size: rem(22);
    }
  }

  &-ron {
    opacity: 0.5;
    display: block;
    font-size: rem(18);
    letter-spacing: -0.28px;

    +below($medium) {
      display: inline;
      font-weight: 600;
    }
  }

  &-eur,
  &-ron {
    vertical-align: middle;
  }
}

// Toggle Favorite
.f-toggle-favorite {
  &:hover {
    .i-heart {
      @extend .i-heart-full;
    }
  }
}

// Boxes
.box {
  width: 100%;
  max-width: rem(390);
  margin-bottom: rem(20);
  border: 1px solid $color-border;
  border-radius: rem(4);

  &.-width-full {
    max-width: 100%;

    +below($medium) {
      margin: 0;
    }

    & ^[0]__section {
      +below($small) {
        padding: rem(15);
      }
    }
  }

  +below($medium) {
    max-width: 100%;
    margin-top: rem(30);
    padding: 0;
  }

  &__section {
    clearfix();
    border-bottom: 1px solid $color-border;
    padding: rem(20) rem(40);

    &:last-child {
      border-bottom: 0;
    }

    +below($medium) {
      padding-right: rem(20);
      padding-left: rem(20);
    }
  }
}

// Steps

.step {
  &.is-hidden {
    display: none;
  }
}

.avatar {
  overflow: hidden;
  max-width: rem(180);
  margin-right: auto;
  margin-left: auto;
  border-radius: 100%;

  & > img {
    display: block;
    width: 100%;
    max-width: rem(180);
    height: auto;
  }
}

.facebook-gallery {
  display: flex;
  flex-flow: row wrap;
}

.facebook-picture {
  overflow: hidden;
  position: relative;
  display: flex;
  flex: 25% 0 0;
  height: rem(200);

  &:hover {
    & > img {
        transform: scale(1.2) translate(-50%, -50%);
      }

    & > span {
      visibility: visible;
      opacity: 1;
    }
  }

  & > span {
    @extend .h-z-index-20;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: rgba($color-pallete-1, 0.5);
    color: white;
    text-align: center;
    text-shadow: rem(2) rem(2) rem(2) rgba(black, 0.2);
    transition: all 250ms ease-in-out;
  }

  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    transition: all 250ms ease-in-out;
  }
}

.facebook-more {
  display: flex !important;
  flex: 25% 0 0;
  justify-content: center;
  align-items: center;
  height: rem(200);
  background-color: white;
  text-decoration: none;

  & > span {
    font-size: $font-size-3;
    transition: color 250ms ease-in-out;
  }

  &:hover > span {
    color: $color-pallete-1;
  }
}
