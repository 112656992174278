/**
 * Booking
 * Author: Graffino (http://www.graffino.com)
 */

.booking {
  padding-top: rem(44);
  padding-bottom: rem(20);
  background-color: $color-pallete-9;

  &__title {

  }

  &__subtitle {
    position: relative;
    z-index: 10;
    margin-top: rem(-22);
  }

  &__content {
    padding-top: rem(30);
    padding-bottom: rem(60);
  }

  &__map {

  }

  &__image {
    margin-left: 14%;

    +below($large) {
      margin-left: 0;
    }

    +below($medium) {
      width: 100%;
    }
  }

  &__block {
    margin-top: rem(60);
  }

  &__text {
    width: rem(370);
    margin-bottom: rem(30);

    +below($small) {
      width: auto;
      text-align: center;
    }
  }
}
