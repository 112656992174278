/**
 * Sections
 * Author: Graffino (http://www.graffino.com)
 */


/**
 *  Default Section
 */
$section-padding = @block {
  padding-top: rem(80);
  padding-bottom: rem(80);
}

$section-padding-medium = @block {
  padding-top: rem(40);
  padding-bottom: rem(40);
}

.section {
  position: relative;
  {$section-padding}; // @stylint off

  +below($medium) {
    {$section-padding-medium}; // @stylint off
  }

  &.-background {
    &-white {
      background-color: white;
    }
    &-decoration {
      background-image: url("../images/svgs/placeholder/background.svg");
      background-position: center bottom;
      background-repeat: no-repeat;
    }

    &-decoration-mountains {
      background-image: url("../images/svgs/placeholder/background-mountains.svg");
      background-position: center bottom;
      background-repeat: no-repeat;
    }

    &-gray {
      background-color: $color-background;
    }
  }
}

.section--frame {
  @extend .section;
  margin-top: rem(50);
  background-image: url("../images/svgs/placeholder/background.svg");
  background-position: center bottom;
  background-repeat: no-repeat;

  +below($medium) {
    margin-top: 0;
    padding-top: rem(60);
    padding-bottom:0;
    background-image: none;
    background-color: white;
  }
}

/**
 *  Frame Section
 */

$frame-width = 880;

.frame {
  center(rem($frame-width), pad = 0);
  margin-bottom: rem(60);
  padding: rem(30) rem(30) rem(60);
  background-color: white;

  +below($iphone6) {
    padding-right: 0;
    padding-left: 0;
  }
}
