/**
 * Typography
 * Author: Graffino (http://www.graffino.com)
 */


/**
 * Heading
 */

.heading {
  position: relative;
  color: $color-heading;

  // Colors
  &.-color,
  & .-color {
    &-white {
      color: white;
    }
    &-orange {
      color: $color-pallete-1;
    }
    &-gray {
      color: $color-text;
    }
  }

  // Weights
  &.-weight,
  & .-weight {
    &-light {
      font-weight: 100;
    }
    &-normal {
      font-weight: 300;
    }
    &-semibold {
      font-weight: 500;
    }
    &-bold {
      font-weight: 700;
    }
  }
  // Margins
  &.-no-margin {
    margin: 0;
  }
  &.-no-margin-top {
    margin-top: 0;
  }
  &.-no-margin-bottom {
    margin-bottom: 0;
  }

  &.-margin-bottom-double {
    margin-bottom: rem(50);
  }

  // Sizes
  &.-size-1 {
    font-size: $heading-1-font-size;
    line-height: $heading-1-line-height;
  }
  &.-size-2 {
    font-size: $heading-2-font-size;
    line-height: $heading-2-line-height;
  }
  &.-size-3 {
    font-size: $heading-3-font-size;
    line-height: $heading-3-line-height;
  }
  &.-size-4 {
    font-size: $heading-4-font-size;
    line-height: $heading-4-line-height;
  }
  &.-size-5 {
    font-size: $heading-5-font-size;
    line-height: $heading-5-line-height;
  }
  &.-size-6 {
    font-size: $heading-6-font-size;
    line-height: $heading-6-line-height;
  }
  &.-size-7 {
    font-size: $heading-7-font-size;
    line-height: $heading-7-line-height;
  }

  & .-size-2-for-medium-down {
    +below($medium) {
      font-size: $font-size-2;
      line-height: $line-height-2;
    }
  }

  & .-size-3-for-medium-down {
    +below($medium) {
      font-size: $font-size-3;
      line-height: $line-height-2;
    }
  }

  // Other
  &.-display-block {
    display: block;
  }
  &.-align-center {
    text-align: center;
  }
  &.-case-upper {
    text-transform: uppercase;
  }
  &.-no-break {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &[data-decoration] {
    @extend .h-z-index-10;
    position: relative;

    &::before {
      content: attr(data-decoration);
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      z-index: -1;
      display: block;
      color: $color-pallete-2;
      font-family: $decorative-fontface;
      font-size: rem(100);
      letter-spacing: normal;
      transform: translateY(-50%);
    }

    &.-decoration-size-small {
      &::before {
        font-size: rem(80);
      }
    }
  }
}

.heading--decorative {
  margin-top: 0;
  margin-bottom: 0;
  color: $color-pallete-2;
  font-family: $decorative-fontface;
  font-size: rem(130);

  &.-size {
    &-small {
      font-size: rem(100);
    }
    &-large {
      font-size: rem(140);
    }
  }
}
/**
 * Text
 */

.text {
  color: $color-text;
  font-size: $default-font-size;

  // Colors
  &.-color,
  & .-color {
    &-white {
      color: white;
    }
    &-orange {
      color: $color-pallete-1;
    }
    &-light-orange {
      color: $color-pallete-13;
    }
    &-black {
      color: black;
    }
    &-blue {
      color: $color-pallete-15;
    }
  }

  // Sizes
  &.-size-huge {
    font-size: $font-size-huge;
    line-height: $line-height-huge;
  }
  &.-size-1 {
    font-size: $font-size-1;
    line-height: $line-height-1;
  }
  &.-size-2 {
    font-size: $font-size-2;
    line-height: $line-height-2;
  }
  &.-size-3 {
    font-size: $font-size-3;
    line-height: $line-height-3;
  }
  &.-size-4 {
    font-size: $font-size-4;
    line-height: $line-height-4;
  }
  &.-size-5 {
    font-size: $font-size-5;
    line-height: $line-height-5;
  }
  &.-size-6 {
    font-size: $font-size-6;
    line-height: $line-height-6;
  }
  &.-size-6 {
    font-size: $font-size-6;
    line-height: $line-height-6;
  }
  &.-size-7 {
    font-size: $font-size-7;
    line-height: $line-height-7;
  }

  // Weights
  &.-weight-normal {
    font-weight: 400;
  }
  &.-weight-bold {
    font-weight: 700;
  }
  &.-weight-semibold{
    font-weight: 600;
  }
  &.-weight-black {
    font-weight: 800;
  }

  &.-style {
    &-normal {
      font-style: normal;
    }
  }

  // Others
  &.-align-center {
    display: block;
    text-align: center;
  }
}

&.-weight-semibold{
  font-weight: 600;
}

/**
 * Links
 */

.link {
  position: relative;
  display: inline-block;

  &.-with-decoration {
    &::after {
      content: "";
      position: absolute;
      bottom: 15%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: currentColor;
    }
  }

  &.-no-decoration {
    text-decoration: none;
  }
}
