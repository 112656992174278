/**
 * Prtners
 * Author: Graffino (http://www.graffino.com)
 */


/**
 *  Inner page
 */

.partners {
  background-color: $color-pallete-1;

  &__content {
    padding-top: rem(35);
    +below($small) {
      margin-right: rem(-20);
      margin-left: rem(-20);
    }
  }

  &__logo {
    overflow: hidden;
    overflow-x: scroll;
    margin: rem(50) 0 rem(32) 0;
    padding: 0;
    text-align: center;
    white-space: nowrap;

    +above($small) {
      .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: auto;
        margin-left: auto;
      }
    }

    &::-webkit-scrollbar{
      width: 0;
    }
  }

  &__item {
    span(1/6);
    display: inline-block;
  }

  &__link {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  &__image {
    @extend .h-display-inline-block;
    display: inline-block;
  }
}

.certified {
  &__content {
    padding: rem(15) 0 rem(28) 0;

    +below($small) {
      padding-bottom: 0;
    }
  }

  &__logo {
    margin-top: rem(30);
    padding-left: 0;
    text-align: center;

    +above($small) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    +below($small) {
      padding-left: 0;
    }
  }

  &__link {
    text-decoration: none;
  }

  &__item {
    display: inline-block;
    max-width: rem(130);
    margin-left: rem(68);

    &:first-child {
      margin-left: 0;

      +below($small) {
        margin: 0 auto;
      }
    }

    +below($tablet) {
      margin-left: rem(48);
    }

    +below($small) {
      display: block;
      margin: 0 auto;
      padding-bottom: rem(26);
    }
  }

  &__text {
    overflow: hidden;
    max-height: rem(32);
    margin-top: rem(14);
    color: $color-pallete-9;
  }
}
