/**
 * Footer
 * Author: Graffino (http://www.graffino.com)
 */


/**
 *  Main footer
 */

.information {
  +below($medium) {
    display: none;
  }

  &__content {
    display: inline-block;
    vertical-align: top;
    margin-left: rem(76);

    &:first-child {
      margin-left: 0;
    }

    +below($large) {
      margin-left: rem(30);
    }
  }

  &__title {
    margin-bottom: rem(30);
    color: $color-pallete-1;
    font-family: $sans-fontface;
    font-size: rem(14);
    font-weight: 600;
    text-transform: uppercase;
  }

  &__item {
    margin-bottom: rem(10);
    font-family: $sans-fontface;
    font-size: rem(14);
    font-weight: 400;
  }

  &__link {
    text-decoration: none;
  }
}

.newsletter {
  padding-left: rem(65);

  +below($xlarge) {
    padding-left: 0;
  }

  +below($medium) {
    text-align: center;
  }

  &__block {
    display: inline-block;

    +below($medium) {
      display: block;
    }
  }

  &__connect {
    position: relative;
    bottom: rem(22);
    display: inline-block;
    padding-left: rem(32);

    +below($desktop) {
      padding-left: rem(8);
    }

    +below($large) {
      padding-left: 0;
    }

    +below($medium) {
      display: block;
      margin-top: rem(43);
      margin-bottom: rem(5);
    }
  }

  &__link {
    display: block;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.footer {
  padding-top: rem(44);
  padding-bottom: 0;
  background-color: white;

  +below($medium) {
    text-align: center;
  }

  +below($xsmall) {
    padding-top: rem(22);
  }

  &.-type-splash {
    padding-top: rem(40);
    padding-bottom: rem(40);
    background-color: $color-background;
  }

  &__phone-number {
    display: inline-block;
    font-family: $sans-fontface;
    font-size: rem(16);
    font-weight: 600;
    text-decoration: none;
  }
}
