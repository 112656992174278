/**
 * Manifesto
 * Author: Graffino (http://www.graffino.com)
 */


/**
 *  Manifesto cards
 */

.manifesto {
  padding-top: rem(10);
  padding-bottom: rem(80);
  background-color: $color-pallete-9;

  &__container {
    margin-right: rem(-20);
    margin-left: rem(-20);
  }

  &__subtitle {
    max-width: rem(580);
    margin-right: auto;
    margin-left: auto;
    font-family: $sans-fontface;
    font-size: rem(18);
    font-weight: 400;

    +below($small) {
      margin-bottom: rem(38);
      font-size: rem(16);
    }
  }

  &__block {
    position: relative;
    width: rem(136);
    height: rem(142);

    +below($large) {
      margin: 0 auto;
      margin-top: rem(2);
      margin-bottom: rem(30);
    }
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__text {
    position: absolute;
    top: 50%;
    padding-right: rem(55);
    font-family: $alt-sans-fontface;
    font-size: rem(30);
    font-weight: 300;
    line-height: 1.2;
    transform: translateY(-50%);

    +below($xlarge) {
      font-size: rem(25);
      text-align: center;
    }

    +below($desktop) {
      font-size: rem(20);
    }

    +below($large) {
      position: relative;
      top: 0;
      padding-right: 0;
      transform: translateY(0);
    }
  }

  &__more {
    padding-top: rem(60);
  }

  &__link {
    margin-right: rem(20);
    font-size: rem(14);
    text-decoration: none;
    text-transform: uppercase;
  }

  &__post {
    overflow: hidden;
    position: relative;
    margin-bottom: rem(40);
    padding: rem(30) rem(55);
    will-change: height;

    +above($large) {
      height: rem(200) !important;
      min-height: rem(200) !important;
    }

    +below($small) {
      padding-right: rem(20);
      padding-left: rem(20);
    }

    &.-color {
      &-blue {
        background-color: $color-manifesto-blue;
        color: $color-manifesto-text-blue;
      }

      &-green {
        background-color: $color-manifesto-green;
        color: $color-manifesto-text-green;
      }

      &-brown {
        background-color: $color-manifesto-brown;
        color: $color-manifesto-text-brown;
      }

      &-orange {
        background-color: $color-manifesto-orange;
        color: $color-manifesto-text-orange;
      }
    }
  }
}
