/**
 * Hero
 * Author: Graffino (http://www.graffino.com)
 */


/**
 *  Hero - Not logged in
 */

$hero-overlay-color = #232323;
$hero-overlay-gradient = linear-gradient(-90deg, rgba($hero-overlay-color, 0.20) 19%, rgba(black, 0.70) 65%);

.hero {
  position: relative;
  min-height: rem(400);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: left;

  &__title {
    margin-top: rem(12);
    margin-bottom: rem(12);
  }

  &__subtitle {
    margin-top: rem(5);
    margin-bottom: rem(5);
  }

  +below($medium) {
    min-height: rem(360);
    padding-top: $header-height-mobile;
    text-align: center;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: $hero-overlay-gradient;

    +below($medium) {
      background-color: rgba(black, 0.5);
      background-image: none;
    }
  }

  &__wrapper {
    position: relative;
    @extend .h-z-index-30;
    margin-top: rem(40);
    margin-bottom: rem(50);

    +below($medium) {
      position: relative;
    }
  }

  &__description {
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: rem(200);
    background-image: linear-gradient(180deg, transparent 0, rgba(black, 0.4) 100%);
  }

  @supports (display: flex) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.-type-home {
    &::before {
      background-color: rgba(black, 0.5);
      background-image: none;
    }
  }

  &.is-logged {
    min-height: rem(330);
    padding-top: $header-height;

    +below($medium) {
      min-height: rem(285);
      padding-top: $header-height-mobile;
    }

    &::before,
    &::after {
      content: none;
    }
  }

  &.-type-tall {
    min-height: rem(660);

    & ^[0]__scroll-to {
      bottom: rem(40);
    }

    +below($medium) {
      min-height: rem(520);

      & ^[0]__scroll-to {
        bottom: rem(65);
      }
    }
  }

  &.-type-simple {
    min-height: rem(200);
    padding-top: $header-height;

    +below($medium) {
      min-height: rem(360);
      padding-top:  $header-height-mobile;
    }
  }

  &.-background-no-results {
    background-image: url("../images/placeholders/bg-no-results.jpg") !important;
  }

  &.not-logged {
    min-height: rem(690);

    +below($medium) {
      min-height: rem(540);
      padding-top: 0;
    }
  }

  &__logo {
    position: relative;
    display: none;

    +below($medium) {
      display: block;
      margin: rem(20) auto rem(40);
    }
  }

  &__scroll-to {
    position: absolute;
    bottom: rem(28);

    &.-type-centered {
      right: 0;
      left: 0;
    }

    +below($medium) {
      right: 0;
      bottom: rem(18);
      left: 0;
    }
  }

  &__video {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
  }

  &__search {
    padding-top: rem(30);
    padding-bottom: rem(60);

    +below($small) {
      padding-top: 0;
      padding-bottom: rem(85);
    }
  }
}
