/**
 * Datepicker
 * Author: Graffino (http://www.graffino.com)
 */


 // Datepicker input styles
.datepicker {
  border: 0;
  background-color: white;
  color: black;
}

// Root container
.ui-datepicker {
  @extend .h-z-index-40;
  display: none;
  padding: rem(15) rem(50);
  background: white;
  box-shadow: 0 rem(2) rem(10) 0 rgba(black, 0.1);

  +below($small) {
    left: 0 !important;
    width: 100% !important;
    padding-right: rem(30);
    padding-left: rem(30);
  }
}

.ui-datepicker-calendar {
  +below($small) {
    margin: 0 auto;
  }
}

th, td {
  padding-right: rem(6);
  padding-left: rem(6);
  font-size: $font-size-5;
  font-weight: 600;
  line-height: rem(34);
  text-align: center;

  +below($small) {
    padding-right: 0;
    padding-left: 0;
  }

  a {
    display: inline-block;
    width: rem(34);
    height: rem(34);
  }
}

.ui-state-active {
  background-image: linear-gradient(to right, white 50%, $color-pallete-8 50%, white 50%, $color-pallete-8 50%);

  & a {
    border-radius: 100%;
    background-color: $color-pallete-1;
    background-image: none;
    color: white;

    &:hover {
      color: white;
    }
  }
}

.ui-state-default {
  text-decoration: none;
}

// Header container
.ui-datepicker-header {
  position: relative;
  margin-top: rem(15);
  margin-bottom: rem(25);
}

.ui-datepicker-title {
  color: black;
  font-size: $font-size-5;
  font-weight: 600;
  text-align: center;
}


// Arrows
.ui-datepicker-prev,
.ui-datepicker-next {
  position: absolute;
  top: 50%;
  display: inline-block;
  width: rem(36);
  height: rem(36);
  color: transparent;
  font-size: 1px;
  transform: translateY(-50%);
}
.ui-datepicker-prev {
  left: 0;
}
.ui-datepicker-next {
  right: 0;
}
.ui-icon-circle-triangle-e {
  @extend .i-arrow-btn-right;
}
.ui-icon-circle-triangle-w {
  @extend .i-arrow-btn-left;
}

// Selected range
.ui-selected-range {
  background-color: $color-pallete-8;

  & + .ui-state-active {
      background-image: linear-gradient(to left, white 50%, $color-pallete-8 50%, white 50%, $color-pallete-8 50%);
  }
}
