/**
 * Popup
 * Author: Graffino (http://www.graffino.com)
 */

// Plugin overrides
.mfp {
  &-bg {
    background-color: rgba(black, 0.7);
  }

  &-container {
    margin-top: rem(50);
    margin-bottom: rem(50);

    +below($medium) {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
    }
  }
}

// Modaly styles
.modal {
  position: relative;
  max-width: rem(600);
  margin-right: auto;
  margin-left: auto;
  border-radius: rem(4);
  padding-top: rem(40);
  padding-bottom: rem(40);
  background-color: white;

  &-container {
    display: none;
  }

  &.-size-wide {
    max-width: rem(1100);
  }

  +below($medium) {
    max-width: 100%;
    border-radius: 0;
  }

  &.-has-decoration {
    background-image: url("../images/svgs/decoration.svg");
    background-position: center bottom;
    background-repeat: no-repeat;
  }

  &__close {
    @extend .h-z-index-20;
    position: absolute;
    top: rem(16);
    left: rem(16);

    &.-position-right {
      right: rem(16);
      left: auto;
    }
  }
}
