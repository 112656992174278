/**
 * Google - Map
 * Author: Graffino (http://www.graffino.com)
 */


.google-map {
  height: rem(555);
}

.map {
  position: relative;
  background-top: rem(10);

  &__header {
    @extend .h-z-index-10;
    position: absolute;
    right: 0;
    left: 0;
    background-color: rgba($color-valid, 0.8);
    text-align: center;
  }

  &.-type-simple {
    .gmnoprint {
      display: none !important;
    }

    [title~="Google"] {
      display: none !important;
    }
  }
}
